import { template as template_490f726a7f2046c9bc19270b3db37b20 } from "@ember/template-compiler";
const FKControlMenuContainer = template_490f726a7f2046c9bc19270b3db37b20(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_d29014d1445c470d90097c810f6a1e9c } from "@ember/template-compiler";
const SidebarSectionMessage = template_d29014d1445c470d90097c810f6a1e9c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

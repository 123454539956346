import { template as template_381821f280b243508f7b0a19847ebf00 } from "@ember/template-compiler";
const FKLabel = template_381821f280b243508f7b0a19847ebf00(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
